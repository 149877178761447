import { InjectionToken } from '@angular/core';

export const ENV_CONFIG = new InjectionToken<EnvConfig>('ENV_CONFIG');

export interface EnvConfig {
  production: boolean;
  frameTarget: string;
  baseRegion?: string;
  productName?: string;
  cdn: {
    project: string;
    base: string;
  };
  serviceDomains: {
    dp: string;
    oam: string;
    login: string;
    cdsso: string;
    protocol: string;
    gcp: string;
    axway?: string;
    wasBackend?: string;
    experienceLayer?: string;
  };
  oauth2Config: {
    grant_type?: string;
    client_id?: string;
    client_secret?: string;
    redirect_uri?: string;
    domain_name?: string;
    env?: string;
    otp_username?: string;
    otp_password?: string;
  };
  firebaseConfig?: {
    apiKey: string;
    authDomain: string;
    databaseURL: string;
    projectId: string;
    storageBucket: string;
    messagingSenderId: string;
    appId: string;
    measurementId?: string;
  };
  validDomains?: string[];
  platforms?: {
    appId: string;
    domain: string;
    platform: string;
    title: string;
  }[];
  redirectWhitelist?: string[];
  defaultUbk?: number;
  ssoConfig?: {
    expressScriptUrl: string;
    larkUrl?: string;
    hmLarkUrl?: string;
    beneLarkUrl?: string;
    amwellProviderId: string;
    amwellRegisterUrl: string;
    amwellConvergeUrl: string;
    springHealthUrl: string;
    hmSpringHealthUrl: string;
    beneSpringHealthUrl: string;
    kyruusProviderId: string;
  };
  mtmAppId?: string;
  smsTextTermsUrl?: string;
  flags?: Record<string, boolean>;
  firebaseAuthRegion?: string;
}

/**
 * Simple object check.
 * @param item
 * @returns {boolean}
 */
function isObject(item) {
  return item && typeof item === 'object' && !Array.isArray(item);
}

/**
 * Deep merge two objects.
 * @param target
 * @param ...sources
 */
export function mergeDeep(target, ...sources) {
  if (!sources.length) {
    return target;
  }
  const source = sources.shift();

  if (isObject(target) && isObject(source)) {
    for (const key in source) {
      if (isObject(source[key])) {
        if (!target[key]) {
          Object.assign(target, { [key]: {} });
        }
        mergeDeep(target[key], source[key]);
      } else {
        Object.assign(target, { [key]: source[key] });
      }
    }
  }

  return mergeDeep(target, ...sources);
}
